import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { config } from './config/index.js';
import Layout from './Layout.jsx';
import { IconProvider } from './context/IconContext'; // Import IconProvider
import { Main, FAQ, AboutPage, Contact, Download, ResetPage } from './components/index.js';

const { baseURL } = config;

export default function App() {
  const [images, setImages] = useState([]);
  return (
    <IconProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/main" element={<Main />} />
            <Route path="/" element={<Main />} />
            <Route path="/reset" element={<ResetPage />} /> {/* Fixed here */}
            <Route path="/faq" element={<FAQ />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/download" element={<Download />} />
            <Route path="/legal" element={<AboutPage defaultSection="Legal" />} />
          </Routes>
        </Layout>
      </Router>
    </IconProvider>
  );
}
