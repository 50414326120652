import React from 'react';
import PropTypes from 'prop-types';
import '../master.css';

const Privacy = () => {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <p>This is the privacy policy page.</p>
    </div>
  );
};

Privacy.propTypes = {
  // Add any props validation if needed in the future
};

export default Privacy;
