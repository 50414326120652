import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import './index.css';

const container = document.getElementById('root'); // Ensure this matches the ID in your index.html
if (container) {
  const root = createRoot(container); // Use createRoot to create a root.

  root.render(
    <>
      <ReactNotifications />
      <App />
    </>
  );
} else {
  console.error('Failed to find the root element.');
}
