import React from 'react';
import '../master.css';
import PropTypes from 'prop-types';



// Main LandingContainer Component - Combines all the components
const Contact = () => {
  return (
    <div className="contactus-container">
    <h1>Contact Us</h1>
      <p>If you have any questions about our app or our company, please visit our Frequently Asked Questions (FAQ) <a href="/faq">section</a>.
<br/><br/>
To contact us, please email us at support@phienx.com and we will get back to you as soon as we can.</p>
    </div>
  );
};

export default Contact;

