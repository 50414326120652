// FAQ.js
import React, { useState } from 'react';
import Question from './Question';
import faqQuestions from './faq_questions.json';
import '../master.css';

const FAQ = () => {
  // Extract all unique subjects from faq_questions.json
  const subjects = ["All", ...new Set(Object.values(faqQuestions).map(q => q.subject))];

  // State for the selected subject
  const [selectedSubject, setSelectedSubject] = useState("All");

  // Convert the questions object into an array of entries
  const questionEntries = Object.keys(faqQuestions).map(key => ({
    id: key,
    ...faqQuestions[key]
  }));

  // Filter questions based on selected subject
  const filteredQuestions = selectedSubject === "All"
    ? questionEntries
    : questionEntries.filter(question => question.subject === selectedSubject);

  // Chunk the questions into rows of 3
  const chunkedQuestions = [];
  for (let i = 0; i < filteredQuestions.length; i += 3) {
    chunkedQuestions.push(filteredQuestions.slice(i, i + 3));
  }

  // If the last row has fewer than 3 items, fill it with placeholders
  const lastRow = chunkedQuestions[chunkedQuestions.length - 1];
  if (lastRow && lastRow.length < 3) {
    const placeholdersNeeded = 3 - lastRow.length;
    for (let i = 0; i < placeholdersNeeded; i++) {
      lastRow.push({ id: `placeholder-${i}`, placeholder: true });
    }
  }

  return (
      <div className="faq-container">
        {/* Subject selector dropdown */}
        <div className="faq-subject-selector">
          <label htmlFor="subject-select">Filter by Subject: </label>
          <select
            id="subject-select"
            value={selectedSubject}
            onChange={(e) => setSelectedSubject(e.target.value)}
          >
            {subjects.map((subject, index) => (
              <option key={index} value={subject}>
                {subject}
              </option>
            ))}
          </select>
        </div>

        {/* Render FAQ rows with 3 questions per row */}
        {chunkedQuestions.map((row, rowIndex) => (
          <div className="faq-row" key={rowIndex}>
            {row.map(question => (
              question.placeholder ? (
                // Add the 'empty' class to the placeholder boxes
                <div key={question.id} className="question-box empty" />
              ) : (
                <Question key={question.id} question_id={question.id} />
              )
            ))}
          </div>
        ))}
      </div>
  );
};

export default FAQ;
