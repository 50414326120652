
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'animate.css'; // Import animate.css animations

const QuestionPopup = ({ questionData, onClose }) => {
  const [isClosing, setIsClosing] = useState(false);

  // Disable body scroll when popup opens
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Lock body scrolling
    return () => {
      document.body.style.overflow = 'auto'; // Restore body scrolling on close
    };
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    // Wait for the fade-out animation to complete before actually closing
    setTimeout(() => {
      onClose();
    }, 500); // Duration of fadeOut animation (adjust based on animation duration)
  };

  return (
    <div
      className={`popup-container animate__animated ${
        isClosing ? 'animate__fadeOut' : 'animate__fadeIn'
      }`}
      style={{ animationDuration: '0.5s' }} // Adjust to match fadeOut timing
    >
      <div className="popup-content">
        <button className="close-button" onClick={handleClose}>
          X
        </button>
        <h2>{questionData.question}</h2>
        <div className="popup-scroll-content" dangerouslySetInnerHTML={{ __html: questionData.content }} />
      </div>
    </div>
  );
};

QuestionPopup.propTypes = {
  questionData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired // Include the question field here
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default QuestionPopup;
