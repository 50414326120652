import React from 'react';
import PropTypes from 'prop-types';

const Back = ({ className = '', onClick = null }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="32" 
      d="m112 352l-64-64l64-64" 
    />
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth="32" 
      d="M64 288h294c58.76 0 106-49.33 106-108v-20" 
    />
  </svg>
);

Back.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Back;
