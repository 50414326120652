import React from 'react';
import PropTypes from 'prop-types';

const List = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={className}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path d="M2 9h9v1H2zm0 3h8v1H2zm0-6h12v1H2zm0-3h11v1H2z" />
  </svg>
);

List.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default List;

