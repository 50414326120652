import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import * as Icons from '../components/icons/index.js';

const IconContext = createContext();

export const IconProvider = ({ children }) => {
  return (
    <IconContext.Provider value={Icons}>
      {children}
    </IconContext.Provider>
  );
};

IconProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useIcons = () => {
  return useContext(IconContext);
};
