import React from 'react';
import PropTypes from 'prop-types';
import '../master.css';

// Vision Component
const Vision = () => (
  <div className="section">
    <h2 className="label">Vision</h2>
    <p className="content">
      To be the premier platform for enhancing emotional intelligence through innovative and engaging gamified mental health solutions. We aim to create a global community where individuals securely share their health data, driving significant advancements in mental health research and contributing to societal well-being.
    </p>
  </div>
);

// Mission Component
const Mission = () => (
  <div className="section">
    <h2 className="label">Mission</h2>
    <p className="content">
      Our mission is to empower users to understand and manage their emotions through an engaging gamified AI experience. We provide innovative tools that enhance emotional intelligence, facilitate effective communication with therapists, and support broader health research to benefit society.
    </p>
  </div>
);

// Values Component
const Values = () => (
  <div className="section">
    <h2 className="label">Values</h2>
    <div className="content">
      <ValueItem title="Do No Harm" description="Ensuring our system and data are never used in a manner that could harm individuals." />
      <ValueItem title="Privacy and Security" description="Prioritizing user privacy and maintaining the highest standards of data security." />
      <ValueItem title="Empowerment" description="Enabling users to take control of their emotional health and contribute positively to societal well-being." />
      <ValueItem title="Innovation" description="Leveraging AI to provide personalized emotional support and actionable insights." />
      <ValueItem title="Transparency" description="Keeping users informed about how their data is used and the positive impact it has on society." />
      <ValueItem title="Inclusivity" description="Embracing diversity and ensuring our solutions are accessible and beneficial to all." />
      <ValueItem title="User-Centric" description="Focusing on user needs and continuously improving our platform based on their feedback and emerging research." />
    </div>
  </div>
);

// ValueItem Component
const ValueItem = ({ title, description }) => (
  <div>
    <h3 className="sublabel">{title}</h3>
    <p className="subcontent">{description}</p>
  </div>
);

// Add PropTypes for ValueItem
ValueItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

// ShortTermObjectives Component
const ShortTermObjectives = () => (
  <div className="section">
    <h2 className="label">Short Term Objectives</h2>
    <ObjectiveItem title="Launch the Gamified Mental Health App" 
      points={[
        "Develop and release the app with core features: emotional logging, affirmation quotes, journaling, and journal summaries.",
        "Aim for 10,000 downloads within the first three months."
      ]} />
    <ObjectiveItem title="User Engagement" 
      points={[
        "Promote the app to build a user base and gather initial feedback for improvements.",
        "Achieve a 40% user retention rate in the first three months, with a plan to increase this rate over time through ongoing engagement strategies."
      ]} />
    <ObjectiveItem title="Privacy and Security Compliance" 
      points={[
        "Ensure the app meets all privacy and security standards to protect user data.",
        "Obtain relevant certifications and conduct regular audits."
      ]} />
  </div>
);

// ObjectiveItem Component
const ObjectiveItem = ({ title, points }) => (
  <div>
    <h3 className="sublabel">{title}</h3>
    {points.map((point, index) => (
      <p key={index} className="subcontent">{point}</p>
    ))}
  </div>
);

// Add PropTypes for ObjectiveItem
ObjectiveItem.propTypes = {
  title: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// Main AboutUs Component
const About= () => (
  <div className="about-container">
    <Vision />
    <Mission />
    <Values />
    <ShortTermObjectives />
  </div>
);

export default About;
