import React from 'react';
import '../master.css';
import PropTypes from 'prop-types';

// HeaderText Component - For the main title and subtitle
const HeaderText = () => {
  return (
    <div className="header-text">
      <h1>A companion for your Emotional Health Journey.</h1>
      <p>E.M.A. is an emotional health app that helps you track your emotions over time</p>
    </div>
  );
};

// FeatureCard Component - For the small card sections at the bottom
const FeatureCard = ({ cls, icon, title, description }) => {
  return (
    <div className="feature-card">
      <div className={`icon-container ${cls}`}>
        <img src={icon} alt={title} className="feature-icon" />
      </div>
      <div className="feature-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

// Define PropTypes for FeatureCard
FeatureCard.propTypes = {
  cls: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,       // Icon must be a string
  title: PropTypes.string.isRequired,      // Title must be a string
  description: PropTypes.string.isRequired // Description must be a string
};


const WatchDisplay = () => {
  return (
    <div className="platy-display">
      <div className="platy-text">
        <div className="download-container">
          <button className="download-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 842.32007 1000.0001"
              className="apple-logo"
            >
              <path
                d="M824.66636 779.30363c-15.12299 34.93724-33.02368 67.09674-53.7638 96.66374-28.27076 40.3074-51.4182 68.2078-69.25717 83.7012-27.65347 25.4313-57.2822 38.4556-89.00964 39.1963-22.77708 0-50.24539-6.4813-82.21973-19.629-32.07926-13.0861-61.55985-19.5673-88.51583-19.5673-28.27075 0-58.59083 6.4812-91.02193 19.5673-32.48053 13.1477-58.64639 19.9994-78.65196 20.6784-30.42501 1.29623-60.75123-12.0985-91.02193-40.2457-19.32039-16.8514-43.48632-45.7394-72.43607-86.6641-31.060778-43.7024-56.597041-94.37983-76.602609-152.15586C10.740416 658.44309 0 598.01283 0 539.50845c0-67.01648 14.481044-124.8172 43.486336-173.25401C66.28194 327.34823 96.60818 296.6578 134.5638 274.1276c37.95566-22.53016 78.96676-34.01129 123.1321-34.74585 24.16591 0 55.85633 7.47508 95.23784 22.166 39.27042 14.74029 64.48571 22.21538 75.54091 22.21538 8.26518 0 36.27668-8.7405 83.7629-26.16587 44.90607-16.16001 82.80614-22.85118 113.85458-20.21546 84.13326 6.78992 147.34122 39.95559 189.37699 99.70686-75.24463 45.59122-112.46573 109.4473-111.72502 191.36456.67899 63.8067 23.82643 116.90384 69.31888 159.06309 20.61664 19.56727 43.64066 34.69027 69.2571 45.4307-5.55531 16.11062-11.41933 31.54225-17.65372 46.35662zM631.70926 20.0057c0 50.01141-18.27108 96.70693-54.6897 139.92782-43.94932 51.38118-97.10817 81.07162-154.75459 76.38659-.73454-5.99983-1.16045-12.31444-1.16045-18.95003 0-48.01091 20.9006-99.39207 58.01678-141.40314 18.53027-21.27094 42.09746-38.95744 70.67685-53.0663C578.3158 9.00229 605.2903 1.31621 630.65988 0c.74076 6.68575 1.04938 13.37191 1.04938 20.00505z"
                fill="black"
              />
            </svg>
            Start growing your Emotional health at the Apple App Store
          </button>
        </div>
        <p>
          Trouble downloading it to your Apple iPhone? See our <a href="/contact">help page</a>
        </p>
      </div>
      <div className="platy-image-container">
        <img src="/images/group.png" alt="Apple Watch" className="platy-image" />
      </div>
    </div>
  );
};

// Main LandingContainer Component - Combines all the components
const LandingContainer = () => {
  return (
    <div className="landing-container">
      <HeaderText />
      <WatchDisplay />
      <div className="features-section">
        <FeatureCard
          cls="ios"
          icon="/images/ios-logo.png"
          title="Beta App"
          description="Excuse our dust while we work through our Beta launch!"
        />
        <FeatureCard
          cls="android"
          icon="/images/android-logo.png"
          title="Coming soon to Android"
          description="We’re working diligently to get through our Beta and prepare for public launch."
        />
      </div>
    </div>
  );
};

export default LandingContainer;

