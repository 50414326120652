import React from 'react';
import PropTypes from 'prop-types';
import '../master.css';
import 'animate.css'; // Ensure animate.css is imported


// JavaScript function to navigate to a specific URL
const handleNavigation = (url) => {
  window.location.href = url; // Navigate to the URL
};

// Navbar Item component
const NavItem = ({ label, url }) => {
  return (
    <li
      className="nav-item animate__animated animate__pulse"
      onClick={() => handleNavigation(url)} // Trigger the function on click
    >
      {label}
    </li>
  );
};

NavItem.propTypes = {
  label: PropTypes.string.isRequired, // Validate label as a required string
  url: PropTypes.string.isRequired,   // Validate that each item has a URL
};

// Home Icon Component
const Home = () => {
  return (
    <div className="home animate__animated animate__fadeInLeft">
      <img src="/images/logo.png" alt="Logo" className="home-icon" />
      <span onClick={() => handleNavigation('/')}>Home</span>
    </div>
  );
};

// Actual Navbar component
const Navbar = () => {
  return (
    <nav className="navbar">
      <Home />
      <ul className="nav-items">
        <NavItem label="Get App" url="/download" />
        <NavItem label="About" url="/about" />
        <NavItem label="FAQ" url="/faq" />
        <NavItem label="Contact" url="/contact" />
      </ul>
    </nav>
  );
};

export default Navbar;

