import React from 'react';
import PropTypes from 'prop-types';

const Novelai = ({ className, onClick }) => (
  <svg
    viewBox="0 0 33 40"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.89418 31.1173C4.51814 28.8707 2.83212 27 0.836131 25.7099C0.26793 25.3426 0.124452 24.5271 0.540438 23.9935C4.15593 19.356 9.79294 7.20752 12.7415 0.590996C13.181 -0.395095 14.6883 -0.072575 14.6883 1.007V18.6289C13.1242 19.322 12.0332 20.888 12.0332 22.7089C12.0332 23.3739 12.1787 24.0049 12.4397 24.5719L5.89418 31.1173ZM7.34675 33.8702C8.03773 35.393 8.61427 37.0257 9.07635 38.7222C9.19588 39.1611 9.59101 39.4713 10.0459 39.4713H16.4937H22.9416C23.3964 39.4713 23.7916 39.1611 23.9111 38.7222C24.3732 37.0257 24.9497 35.393 25.6407 33.8702L22.211 30.4405L19.3551 33.2964C19.4281 33.5544 19.4672 33.8266 19.4672 34.108C19.4672 35.7503 18.1358 37.0817 16.4935 37.0817C14.8512 37.0817 13.5198 35.7503 13.5198 34.108C13.5198 32.4657 14.8512 31.1343 16.4935 31.1343C16.7448 31.1343 16.9888 31.1655 17.2219 31.2242L20.1083 28.3378L18.4762 26.7057C17.879 27.0025 17.2058 27.1694 16.4937 27.1694C15.7816 27.1694 15.1084 27.0025 14.5112 26.7057L7.34675 33.8702ZM27.0933 31.1173C28.4693 28.8707 30.1553 27 32.1513 25.7099C32.7195 25.3426 32.863 24.5271 32.447 23.9935C28.8315 19.356 23.1945 7.20752 20.2459 0.590996C19.8065 -0.395095 18.2992 -0.072575 18.2992 1.007V18.6289C19.8632 19.322 20.9542 20.888 20.9542 22.7089C20.9542 23.3739 20.8087 24.0049 20.5478 24.5718L27.0933 31.1173Z"
    />
  </svg>
);

Novelai.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Novelai;

