import React from 'react';
import PropTypes from 'prop-types';

const Home = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path d="M5 12H3l9-9l9 9h-2M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7" />
    <path d="M9 21v-6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v6" />
  </svg>
);

Home.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Home;
