import React, { useState } from 'react';
import '../master.css';
import Close from '../icons/Close.jsx';
const NewUpdatesHeader = () => {
  const [isHiding, setIsHiding] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsHiding(true);
    // Wait for the animation to complete (e.g., 500ms), then hide the component
    setTimeout(() => {
      setIsVisible(false);
    }, 500); // Match the duration of the CSS transition
  };

  return (
    isVisible && (
      <div className={`new-updates-header ${isHiding ? 'hide' : ''}`}>
        <p>New Updates Available! Click here to see more details.</p>
        <button className="close-button" onClick={handleClose}>
          <Close />
        </button>
      </div>
    )
  );
};

export default NewUpdatesHeader;
