import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './components/Navbar'; // Ensure the correct path to Navbar
import NewUpdatesHeader from './components/NewUpdatesHeader';
import Footer from './components/Footer';
import './Layout.css';

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <NewUpdatesHeader />
      <Navbar />
      <main className="content">
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

