import React from 'react';
import PropTypes from 'prop-types';

const Open = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={className}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="#cad3f5" d="m1.875 8l.686-2.743a1 1 0 0 1 .97-.757h10.938a1 1 0 0 1 .97 1.243l-.315 1.26M6 13.5H2.004A1.5 1.5 0 0 1 .5 12V3.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1" />
      <path stroke="#a6da95" d="M14.5 15.5L11 12l-2.5 2.5" />
      <path stroke="#7dc4e4" d="M9.5 8.5h4.997a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H9.5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1" />
      <circle cx="13.5" cy="10.5" r=".5" stroke="#eed49f" />
    </g>
  </svg>
);

Open.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Open;
