import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import faqQuestions from './faq_questions.json';
import QuestionPopup from './QuestionPopup.js';

const Question = ({ question_id }) => {
  const [questionData, setQuestionData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const data = faqQuestions[question_id];
    if (data) {
      setQuestionData(data);
    } else {
      console.error(`No question found for id ${question_id}`);
    }
  }, [question_id]);

  const openPopup = () => setIsPopupOpen(true);

  if (!questionData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="question-box" onClick={openPopup}>
        <h4>{questionData.subject}</h4>
        <p>{questionData.question}</p>
      </div>
      {isPopupOpen && (
        <QuestionPopup
          questionData={questionData}
          onClose={() => setIsPopupOpen(false)}
        />
      )}
    </>
  );
};

Question.propTypes = {
  question_id: PropTypes.string.isRequired,
};

export default Question;
