import React from "react";
import { useLocation } from "react-router-dom";
import ResetPassword from "./ResetPassword";

const ResetPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  console.log("Token:", token);  // Debugging to verify if the token is captured

  if (!token) {
    return <p>Error: Token is missing in the URL.</p>;
  }

  return (
    <div>
      <div className="reset-password-header">
        Password Reset
      </div>
      <ResetPassword token={token} />
    </div>
  );
};

export default ResetPage;
