import React from 'react';
import '../master.css';
import PropTypes from 'prop-types';



// Main LandingContainer Component - Combines all the components
const Download = () => {
  return (
    <div className="download">
      <h1 className="header">Download App</h1>
        <div className="text">
        <p>We are currently beta testing the app, and are very excited for everyone to be able to use it.</p>
        <p>Check back here after the public launch for instructions on how you can get it!</p>
      </div>
    </div>
  );
};

export default Download;

