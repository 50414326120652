import React, { useState } from "react";
import axios from "axios";
import '../master.css';


const ResetPassword = ({ token }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear previous errors
    setSuccessMessage(""); // Clear previous success message

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await axios.post("https://dev1.phienx.com:8443/api/auth/reset-password", {
        token,
        password,
      });

      if (response.status === 200) {
        setSuccessMessage("Password reset successfully.");
      }
    } catch (error) {
      setIsSubmitting(false);

      if (error.response) {
        const { status, data } = error.response;

        if (status === 400 && data.detail === "RESET_PASSWORD_BAD_TOKEN") {
          setErrorMessage("Invalid or expired reset token.");
        } else if (status === 422) {
          setErrorMessage("Validation error: " + data.detail[0].msg);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      } else {
        setErrorMessage("Failed to connect to the server.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="reset-password-container">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="password">New Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {password && confirmPassword && (
            <p className={password === confirmPassword ? "match" : "no-match"}>
              {password === confirmPassword ? "Passwords match!" : "Passwords do not match"}
            </p>
          )}
        </div>

        <button type="submit" disabled={isSubmitting || password !== confirmPassword}>
          {isSubmitting ? "Submitting..." : "Reset Password"}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
