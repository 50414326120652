import React, { useState, useEffect } from 'react';
//import MapWithGavels from './MapWithGavels';
import '../master.css';
import axios from 'axios';
import Landing from '../Landing/index.js';

const Main = () => {
  const [mapsData, setMapsData] = useState([]);
  const [selectedMap, setSelectedMap] = useState(null);
  const server = 'ultros';
  const apiUrl = `/api/housing/server/${server}`;

  /*useEffect(() => {
    const fetchGavels = async () => {
      try {
        const response = await axios.get(apiUrl);
        setMapsData(response.data);
        setSelectedMap(response.data[0]); // Set the first map as default
      } catch (error) {
        console.error('Error fetching map data:', error);
      }
    };

    fetchGavels();
  }, [apiUrl]);

  const handleMapChange = (event) => {
    const selectedMapName = event.target.value;
    const map = mapsData.find((m) => m.name === selectedMapName);
    setSelectedMap(map);
  };*/

  return (
    <div className="content-container">
      <Landing />
    </div>
  );
};

export default Main;

