import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Legal from './Legal';
import Privacy from './Privacy';
import About from './About';
import '../master.css';

const AboutPage = ({ defaultSection = 'About' }) => {
  const [activeComponent, setActiveComponent] = useState(defaultSection);

  // If the defaultSection changes (like when navigating directly to /legal), update the activeComponent
  useEffect(() => {
    setActiveComponent(defaultSection);
  }, [defaultSection]);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Legal':
        return <Legal />;
      case 'Privacy':
        return <Privacy />;
      case 'About':
      default:
        return <About />;
    }
  };

  return (
    <div className="about-page">
      <nav className="about-nav">
        <button onClick={() => setActiveComponent('About')}>About</button>
        <button onClick={() => setActiveComponent('Privacy')}>Privacy</button>
        <button onClick={() => setActiveComponent('Legal')}>Legal</button>
      </nav>
      <div className="about-content">
        {renderComponent()}
      </div>
    </div>
  );
};

// Add prop types for validation
AboutPage.propTypes = {
  defaultSection: PropTypes.string
};

export default AboutPage;
