import React from 'react';
import PropTypes from 'prop-types';

const Done = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <g fill="none">
      <circle cx="12" cy="12" r="8" fillOpacity="0.25" />
      <path strokeWidth="1.2" d="m8.5 11l2.894 2.894a.15.15 0 0 0 .212 0L19.5 6" />
    </g>
  </svg>
);

Done.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Done;

