import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.17em"
    height="1em"
    viewBox="0 0 1792 1536"
    className={className}
    onClick={onClick}
    style={{
      cursor: 'pointer',
    }}
  >
    <path
      fill="currentColor"
      d="m1175 1193l146-146q10-10 10-23t-10-23l-233-233l233-233q10-10 10-23t-10-23l-146-146q-10-10-23-10t-23 10L896 576L663 343q-10-10-23-10t-23 10L471 489q-10 10-10 23t10 23l233 233l-233 233q-10 10-10 23t10 23l146 146q10 10 23 10t23-10l233-233l233 233q10 10 23 10t23-10m617-1033v1216q0 66-47 113t-113 47H160q-66 0-113-47T0 1376V160Q0 94 47 47T160 0h1472q66 0 113 47t47 113"
    />
  </svg>
);

Close.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Close;
