import ReactDOMServer from 'react-dom/server';
import React, { useState } from 'react';
import PropTypes from 'prop-types';  // Import PropTypes for validation
import ReactMarkdown from 'react-markdown';  // Import react-markdown for Markdown rendering
import '../master.css';

// Import the markdown files
import termsOfService from '../legalDocs/termsOfService.md';
import privacyPolicy from '../legalDocs/privacyPolicy.md';
import cookiePolicy from '../legalDocs/cookiePolicy.md';

// Legal Document Display Component
const LegalDocument = ({ content }) => {
  return (
    <div className="legal-content">
      <ReactMarkdown>{content}</ReactMarkdown> {/* Render Markdown content */}
    </div>
  );
};

// Add PropTypes validation for the LegalDocument component
LegalDocument.propTypes = {
  content: PropTypes.string.isRequired,  // Ensure content is passed as a string and is required
};

// Function to open the standalone page
const openStandalonePage = (content) => {
  // Use ReactDOMServer to render the Markdown as HTML
  const renderedContent = ReactDOMServer.renderToString(<ReactMarkdown>{content}</ReactMarkdown>);

  // Create a new window
  const newWindow = window.open('', '_blank', 'width=800,height=600');

  // Write the content into the new window with improved CSS
  newWindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Legal Document</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            padding: 20px;
            line-height: 1.6;
            color: #333;
            background-color: #f9f9f9;
          }
          h1, h2, h3, h4, h5, h6 {
            color: #ffcc00;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
            line-height: 1.2;
          }
          p {
            margin-bottom: 1.5rem;
            font-size: 1.1rem;
          }
          a {
            color: #007bff;
            text-decoration: underline;
          }
          a:hover {
            color: #0056b3;
          }
          ul, ol {
            margin: 1rem 0;
            padding-left: 1.5rem;
          }
          li {
            margin-bottom: 0.5rem;
          }
          code {
            background-color: #f4f4f4;
            padding: 2px 5px;
            border-radius: 4px;
            font-family: 'Courier New', Courier, monospace;
          }
          pre {
            background-color: #f4f4f4;
            padding: 1rem;
            border-radius: 8px;
            overflow-x: auto;
          }
          blockquote {
            border-left: 4px solid #ffcc00;
            padding-left: 10px;
            margin-left: 0;
            color: #666;
            font-style: italic;
          }
          /* Table Styling */
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 1.5rem;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 0.75rem;
            text-align: left;
          }
          th {
            background-color: #ffcc00;
            color: #333;
          }
          /* Print button styling */
          .print-button {
            position: fixed;
            top: 20px;
            right: 20px;
            padding: 10px 20px;
            background-color: #28a745;
            color: white;
            border: none;
            cursor: pointer;
            border-radius: 5px;
          }
          .print-button:hover {
            background-color: #218838;
          }
        </style>
      </head>
      <body>
        <button class="print-button" onclick="window.print();">Print</button>
        <div>
          ${renderedContent}
        </div>
      </body>
    </html>
  `);

  // Close the document to apply styles
  newWindow.document.close();
};

// Main Legal Component
const Legal = () => {
  const [activeDocument, setActiveDocument] = useState('terms');

  // Get the document content based on the active document
  const getDocumentContent = () => {
    switch (activeDocument) {
      case 'privacy':
        return privacyPolicy;
      case 'cookies':
        return cookiePolicy;
      default:
        return termsOfService;
    }
  };

  return (
    <div className="legal">
      <h1>Legal Information</h1>
      <div className="legal-buttons">
        <button onClick={() => setActiveDocument('terms')}>Terms of Service</button>
        <button onClick={() => setActiveDocument('privacy')}>Privacy Policy</button>
        <button onClick={() => setActiveDocument('cookies')}>Cookie Policy</button>
      </div>

      <div className="standalone-link">
        <a href="#" onClick={() => openStandalonePage(getDocumentContent())}>
          Click here to view standalone
        </a>
      </div>

      <div className="legal-content-container">
        {/* Display the active document with animations */}
        <div className="animate__animated animate__fadeIn">
          <LegalDocument content={getDocumentContent()} />
        </div>
      </div>
    </div>
  );
};

export default Legal;

