import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <g fill="none">
      <path
        fill="currentColor"
        fillOpacity="0.25"
        d="m12.5 7.5l-6.572 6.572c-.212.212-.319.319-.388.45c-.07.13-.1.277-.159.572l-.734 3.67c-.066.333-.1.5-.005.594c.095.095.26.061.593-.005l3.671-.734c.295-.06.442-.089.573-.159s.237-.176.45-.388L16.5 11.5z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.2"
        d="m5.954 19.38l.026-.007l2.628-.657l.049-.012c.218-.054.429-.106.62-.215c.192-.108.345-.262.504-.421l.035-.036l7.194-7.193l.027-.027c.31-.31.585-.585.776-.836c.207-.271.373-.585.373-.976s-.166-.705-.373-.976c-.191-.251-.466-.525-.776-.836l-.027-.026l-.172-.172l-.026-.027c-.31-.31-.585-.584-.836-.776c-.271-.207-.585-.373-.976-.373s-.705.166-.976.373c-.251.192-.525.466-.836.776l-.027.027l-7.194 7.194l-.035.035c-.16.16-.313.313-.421.504c-.109.191-.161.402-.215.62l-.012.049l-.664 2.654l-.007.03a3.4 3.4 0 0 0-.097.476c-.016.165-.015.464.227.705c.24.242.54.243.705.227c.148-.014.323-.058.477-.097zM12.5 7.5l4 4"
      />
    </g>
  </svg>
);

Edit.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Edit;

