// src/config.js

/*************************************************************
 ********************* PRIVATE CONFIG VARS *******************
 *************************************************************/
const backend = {
  host: 'casper',
  port: 8081,
};

const frontend_host = 'casper';




/*************************************************************
 ************************* EXPORTED VARS *********************
 *************************************************************/
const config = {
  backendURL: `http://${backend.host}:${backend.port}`,
  frontendHost: frontend_host,
};





export default config;
