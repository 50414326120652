import React from 'react';
import PropTypes from 'prop-types';

const Save = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={className}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path
      d="M14 0H0v16h16V2zM8 2h2v4H8zm6 12H2V2h1v5h9V2h1.172l.828.828z"
    />
  </svg>
);

Save.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Save;
