import React from 'react';
import PropTypes from 'prop-types';

const Run = ({ className = '', onClick = null }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <g fill="none">
      <path d="M19 10.268c1.333.77 1.333 2.694 0 3.464l-9 5.196c-1.333.77-3-.192-3-1.732V6.804c0-1.54 1.667-2.502 3-1.732z" opacity="0.16" />
      <path strokeLinejoin="round" strokeWidth="2" d="M19 10.268c1.333.77 1.333 2.694 0 3.464l-9 5.196c-1.333.77-3-.192-3-1.732V6.804c0-1.54 1.667-2.502 3-1.732z" />
    </g>
  </svg>
);

Run.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Run;
